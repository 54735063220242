<script lang="ts" setup>
const config = useRuntimeConfig()

useHead({
  title: useNuxtApp().$i18n.t('appNameForTitle'),
  script: computed(() => {
    if (!config.public.ccm.src) {
      return []
    }
    return [
      { src: config.public.ccm.src, referrerpolicy: 'origin' },
    ]
  }),
})
</script>

<template>
  <div class="ShopModule relative font-myriad">
    <ShopLayoutNavbar class="PagePadding" />
    <slot />
    <ShopLayoutFooter />
    <ShopCheckoutDrawerContainer />
  </div>
</template>

<style>
.ShopModule .PagePadding {
    @apply px-3 md:px-8 lg:px-12 xl:px-20 2xl:px-40
}
</style>
