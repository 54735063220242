<script lang="ts" setup>
import currency from 'currency.js'
import type { MachineryAccessoryCategory, MachineryRubrics } from '~/types'

const { isCartDrawerOpen, cartId, rentalTimeFrame } = useShopCart()

const { cartQuery } = useShopQuery()
const { data: cartData, isLoading: isLoadingCart } = cartQuery.getByIdOrCreate(cartId, { rentalTimeFrame })

const isMobileScreen = useIsMobileScreen()

const machineriesDailyTotalPrice = computed(() => {
  if (!cartData.value) {
    return undefined
  }

  let summedDailyMachineryPrice = currency(0)
  for (const machinery of cartData.value.positions) {
    if (machinery.suggestedMachinery?.price) {
      const machineryPrice = machinery.suggestedMachinery.price
      const insurancePrice = calculateInsurancePrice(machineryPrice)
      summedDailyMachineryPrice = summedDailyMachineryPrice.add(machineryPrice).add(insurancePrice)

      // Add the price of all the accessories inside the bundle
      for (const position of machinery.machineryAccessoryCategoryCartPositions) {
        if (position.machineryAccessoryCategoryFilters) {
          const dailyAccessoryPrice = calculateShopMachineryAccessoryCategoryPrice(position.machineryAccessoryCategoryFilters.machineryAccessoryCategory as MachineryAccessoryCategory, machinery.machineryCategoryFilters?.machineryRubric as MachineryRubrics | undefined)
          if (dailyAccessoryPrice) {
            summedDailyMachineryPrice = summedDailyMachineryPrice.add(dailyAccessoryPrice)
          }
        }
      }
    } else if (machinery.machineryAccessoryCategoryFilters) {
      const price = calculateShopMachineryAccessoryCategoryPrice(machinery.machineryAccessoryCategoryFilters.machineryAccessoryCategory as MachineryAccessoryCategory, machinery.machineryCategoryFilters?.machineryRubric as MachineryRubrics | undefined)
      if (price) {
        summedDailyMachineryPrice = summedDailyMachineryPrice.add(price)
      }
    }
  }

  return summedDailyMachineryPrice.value
})
</script>

<template>
  <n-drawer
    v-if="!isMobileScreen"
    v-model:show="isCartDrawerOpen"
    :width="500"
    placement="right"
    class="my-2"
  >
    <n-drawer-content body-content-class="!p-0">
      <template #header>
        <div class="flex items-center gap-2">
          <Icon name="material-symbols:shopping-cart-outline-rounded" />
          {{ $t('shop.checkout.cart.title') }}
        </div>
      </template>

      <div class="flex h-full flex-col overflow-y-auto">
        <div class="flex-1 overflow-y-auto px-4 py-4 sm:px-6">
          <ShopCheckoutDrawerCartData :data="cartData" :is-loading="isLoadingCart" />
        </div>

        <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
          <template v-if="cartData && cartData.positions.length > 0">
            <ShopCheckoutCartItemPriceSummary :value="machineriesDailyTotalPrice" class="text-lg">
              <template #title>
                <p class="space-x-1">
                  <span>
                    {{ $t('shop.booking.price.total') }}
                  </span>
                  <span class="opacity-50 text-sm">
                    {{ $t('shop.checkout.cart.price.plusVat') }}
                  </span>
                </p>
              </template>
            </ShopCheckoutCartItemPriceSummary>
            <p class="text-xs opacity-75 mb-6">
              {{ $t('shop.booking.price.indicator.deliveryCosts') }}
            </p>
          </template>

          <div class="flex flex-col gap-y-5">
            <NuxtLink href="/shop/checkout">
              <n-button
                type="primary"
                size="large"
                block
                @click="isCartDrawerOpen = false"
              >
                {{ $t('shop.checkout.cart.button.toCart') }}
              </n-button>
            </NuxtLink>
            <NuxtLink href="/shop/rent">
              <n-button
                text
                size="large"
                block
                @click="isCartDrawerOpen = false"
              >
                {{ $t('shop.checkout.cart.button.continueShopping') }}
              </n-button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </n-drawer-content>
  </n-drawer>
</template>
