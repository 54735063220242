<script lang="ts" setup>
const { status } = useAuth()
const isMobileScreen = useIsMobileScreen()

const { cartId, isCartDrawerOpen } = useShopCart()
const { cartQuery } = useShopQuery()
const { data } = cartQuery.getByIdOrCreate(cartId)

const route = useRoute()
const routesToHideCheckoutCart = ['/shop/buy', '/shop/buy/machinery-accessory']

const isCheckoutCartHidden = computed(() => routesToHideCheckoutCart.includes(route.path))
const logoLinkPath = computed(() => {
  if (route.path.startsWith('/shop/rent')) {
    return '/shop/rent'
  } else if (route.path.startsWith('/shop/buy')) {
    return '/shop/buy'
  } else {
    return '/shop'
  }
})
</script>

<template>
  <div class="sticky top-0 w-full bg-hos-blue-dark py-4 border-b border-white/10 z-30">
    <div class="flex items-center gap-4 justify-between">
      <NuxtLink :to="logoLinkPath">
        <img src="/branding/Logo-Hanselmann-ohne Slogan_weiss.png" class="h-8 hidden sm:block">
        <img src="/branding/hanselmann-forklift.png" class="h-8 object-contain block sm:hidden">
      </NuxtLink>

      <div class="flex items-center gap-2">
        <CustomerModuleLayoutLanguageSelector :display-text="!isMobileScreen" />

        <!-- Regular authenticated user -->
        <template v-if="status === 'authenticated'">
          <CustomerModuleLayoutUserDropdown />
        </template>

        <!-- Unauthenticated user -->
        <NuxtLink v-else to="/auth/login">
          <n-button type="primary">
            <template #icon>
              <Icon name="material-symbols:login" />
            </template>
            {{ $t('customerModule.navigation.login.title') }}
          </n-button>
        </NuxtLink>

        <template v-if="!isCheckoutCartHidden">
          <NuxtLink href="/shop/checkout" class="md:hidden">
            <n-button type="primary">
              <template #icon>
                <Icon name="material-symbols:shopping-cart-outline-rounded" />
              </template>
            </n-button>
          </NuxtLink>

          <n-badge :value="data?.positions?.length ?? 0" type="info" processing class="hidden md:block">
            <n-button type="primary" @click="isCartDrawerOpen = true">
              <template #icon>
                <Icon name="material-symbols:shopping-cart-outline-rounded" />
              </template>
              <span class="hidden md:block">
                {{ $t('shop.checkout.cart.title') }}
              </span>
            </n-button>
          </n-badge>
        </template>
      </div>
    </div>
  </div>
</template>
