<script lang="ts" setup>
const { t: $t } = useI18n()

import currency from 'currency.js'
import type { ApiShopCartByIdPosition } from '~/layers/customer-module/types'
import type { MachineryAccessoryCategory, MachineryRubrics } from '~/types'

const props = defineProps<{ position: ApiShopCartByIdPosition }>()
defineEmits<{ (e: 'delete', positionId: string): void }>()

const isMobile = useIsMobileScreen()

const machineryAccessoryCategoriesAddedWithMachinery = computed(() => {
  return props.position.machineryAccessoryCategoryCartPositions.map(position => $t(`machineryAccessory.category.${position.machineryAccessoryCategoryFilters?.machineryAccessoryCategory}`))
})

const permanentlyAttachedAccessories = computed(() => {
  if (!props.position.machineryCategoryFilters) {
    return []
  }
  return props.position.machineryCategoryFilters.attachedAccessoryCategories
})

const insurancePricePerDay = computed(() => props.position.suggestedMachinery?.price ? calculateInsurancePrice(props.position.suggestedMachinery.price) : undefined)
const totalPricePerDay = computed(() => {
  let pricePerDay = currency(0)

  if (props.position.suggestedMachinery?.price) {
    pricePerDay = pricePerDay.add(props.position.suggestedMachinery.price)
  }

  if (insurancePricePerDay.value) {
    pricePerDay = pricePerDay.add(insurancePricePerDay.value)
  }

  props.position.machineryAccessoryCategoryCartPositions.forEach((position) => {
    if (position.type === 'machineryAccessoryCategory' && position.machineryAccessoryCategoryFilters) {
      const dailyAccessoryPrice = calculateShopMachineryAccessoryCategoryPrice(position.machineryAccessoryCategoryFilters.machineryAccessoryCategory as MachineryAccessoryCategory, props.position.machineryCategoryFilters?.machineryRubric as MachineryRubrics | undefined)
      if (dailyAccessoryPrice) {
        pricePerDay = pricePerDay.add(dailyAccessoryPrice)
      }
    }
  })

  return pricePerDay.value
})
</script>

<template>
  <li v-if="position.machineryCategoryFilters" class="py-4 first:pt-0 last:pb-0">
    <div class="flex flex-col md:flex-row md:gap-4">
      <div class="h-[75px] w-[75px] flex-shrink-0 overflow-hidden rounded-md">
        <CustomerModulePositionImage
          :src="position.machineryCategoryFilters.previewImage"
          width="75px"
          height="75px"
          :placeholder="{ iconSize: '35px', withText: false }"
        />
      </div>

      <div class="sm:grow sm:space-y-2">
        <div class="flex items-center gap-2 justify-between">
          <h3 class="flex items-center gap-1 text-primary-500">
            <span class="font-semibold text-xl">
              {{ position.machineryCategoryFilters.producerCompanyName }}
              {{ position.machineryCategoryFilters.type.name }}
            </span>
          </h3>

          <div class="shrink-0 flex items-center gap-2">
            <p class="text-sm">
              <template v-if="position.suggestedMachinery?.price === undefined">
                {{ $t('shop.placeholder.onRequest') }}
              </template>
              <template v-else>
                {{ $t('shop.price.perDay', { value: position.suggestedMachinery.price.toLocaleString('de-DE', { minimumFractionDigits: 2 }) }) }}
              </template>
            </p>

            <n-popconfirm
              :width="isMobile ? 300 : undefined"
              placement="top-end"
              @positive-click="$emit('delete', position.id)"
            >
              <template #trigger>
                <n-button type="error" secondary size="small">
                  <template #icon>
                    <Icon name="material-symbols:delete-outline-rounded" />
                  </template>
                </n-button>
              </template>
              <div class="font-myriad mb-1">
                <p>{{ $t('shop.checkout.confirm.removeMachinery') }}</p>
                <p v-if="machineryAccessoryCategoriesAddedWithMachinery.length > 0">
                  {{ $t('shop.checkout.confirm.removeMachineryCategory', { accessoryCategories: machineryAccessoryCategoriesAddedWithMachinery.join(', ') }) }}
                </p>
              </div>
            </n-popconfirm>
          </div>
        </div>

        <template v-if="permanentlyAttachedAccessories.length > 0">
          <ShopCheckoutDrawerItemAccessory
            v-for="(accessory, index) in permanentlyAttachedAccessories"
            :key="index"
            :category="accessory.category"
            hide-price
          />
        </template>

        <template v-if="position.machineryAccessoryCategoryCartPositions.length > 0">
          <template v-for="accessoryPosition in position.machineryAccessoryCategoryCartPositions" :key="accessoryPosition.id">
            <ShopCheckoutDrawerItemAccessory
              v-if="accessoryPosition.type === 'machineryAccessoryCategory' && accessoryPosition.machineryAccessoryCategoryFilters"
              :category="accessoryPosition.machineryAccessoryCategoryFilters.machineryAccessoryCategory"
              :position-id="accessoryPosition.id"
              :machinery-rubric="position.machineryCategoryFilters.machineryRubric"
              @delete="id => $emit('delete', id)"
            />
          </template>
        </template>

        <n-divider />

        <ul>
          <ShopCheckoutCartItemPriceSummary
            :label="$t('shop.checkout.InsurancePricePerDay')"
            :value="insurancePricePerDay"
            class="text-sm"
          />
          <ShopCheckoutCartItemPriceSummary
            :label="$t('shop.checkout.pricePerDay')"
            :value="totalPricePerDay"
            class="text-sm"
          />
        </ul>
      </div>
    </div>
  </li>
</template>
