<script lang="ts" setup>
import type { MachineryAccessoryCategory, MachineryRubrics } from '~/types'

const props = defineProps<{
  category: string
  positionId?: string
  hidePrice?: boolean
  machineryRubric?: string
}>()
defineEmits<{ (e: 'delete', positionId: string): void }>()

const isMobile = useIsMobileScreen()
const dailyPrice = computed(() => calculateShopMachineryAccessoryCategoryPrice(props.category as MachineryAccessoryCategory, props.machineryRubric as MachineryRubrics | undefined))
</script>

<template>
  <li>
    <div class="flex items-center gap-2">
      <div class="shrink-0 h-[30px] w-[30px] overflow-hidden rounded-md grid place-content-center border bg-white">
        <Icon :name="useMachineryAccessoryIcon()[category as MachineryAccessoryCategory]" :style="{ width: '16px', height: '16px' }" />
      </div>

      <div class="grow">
        <div class="flex items-center justify-between gap-2">
          <p class="text-[15px]">
            {{ $t(`machineryAccessory.category.${category}`) }}
          </p>

          <div class="shrink-0 flex items-center gap-2">
            <p v-if="!hidePrice" class="text-sm">
              <template v-if="dailyPrice === undefined">
                {{ $t('shop.placeholder.onRequest') }}
              </template>
              <template v-else>
                {{ $t('shop.price.perDay', { value: dailyPrice.toLocaleString('de-DE', { minimumFractionDigits: 2 }) }) }}
              </template>
            </p>
            <n-popconfirm
              v-if="positionId"
              :width="isMobile ? 300 : undefined"
              placement="top-end"
              @positive-click="$emit('delete', positionId)"
            >
              <template #trigger>
                <n-button type="error" tertiary size="small">
                  <template #icon>
                    <Icon name="material-symbols:delete-outline-rounded" />
                  </template>
                </n-button>
              </template>
              <div class="font-myriad mb-1">
                <p>{{ $t('shop.checkout.confirm.removeMachineryAccessory') }}</p>
              </div>
            </n-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>
