<script lang="ts" setup>
const { t: $t } = useI18n()

import { CartPositionType } from '~/prisma/enums'
import type { ApiShopCartById } from '~/layers/customer-module/types'

defineProps<{ data?: ApiShopCartById | null, isLoading: boolean }>()

const { $trpc, queryClient, useMutation } = useMutationHelpers()
const notification = useNotification()

const removePositionFromCart = useMutation({
  mutationFn: (id: string) => $trpc.shop.cart.removePositionFromCart.mutate({ id }),
  onSuccess: async () => {
    await Promise.all([
      queryClient.invalidateQueries({ queryKey: [SHOP_QUERY_KEY, 'cart'] }),
      queryClient.invalidateQueries({ queryKey: [SHOP_QUERY_KEY, 'machinery'] }),
      queryClient.invalidateQueries({ queryKey: ['deliveryPrice', 'cartPriceData'] }),
    ])
    notification.success({ title: $t('shop.checkout.cart.notification.update'), duration: 4500 })
  },
})
</script>

<template>
  <div>
    <div v-if="isLoading" class="space-y-3">
      <n-skeleton height="120px" :sharp="false" class="pb-6" />
      <n-skeleton height="120px" :sharp="false" class="py-6" />
    </div>

    <CustomerModuleEmptyPlaceholder
      v-else-if="!data || data.positions.length === 0"
      :title="$t('shop.checkout.cart.empty.title')"
      icon-name="material-symbols:shopping-cart-outline-rounded"
    />

    <ul v-else role="list" class="divide-y divide-gray-200">
      <template v-for="position in data.positions" :key="position.id">
        <template v-if="position.type === CartPositionType.machineryCategory">
          <ShopCheckoutDrawerItemMachinery
            :position="position"
            @delete="removePositionFromCart.mutate"
          />
        </template>
        <template v-else-if="position.type === 'machineryAccessoryCategory' && position.machineryAccessoryCategoryFilters">
          <ShopCheckoutDrawerItemAccessory
            :category="position.machineryAccessoryCategoryFilters.machineryAccessoryCategory"
            :position-id="position.id"
            :machinery-rubric="position.machineryCategoryFilters?.machineryRubric"
            class="py-4 first:pt-0 last:pb-0"
            @delete="removePositionFromCart.mutate"
          />
        </template>
      </template>
    </ul>
  </div>
</template>
